<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 分类管理 </template>
      <template #input>
        <a-button class="all_boder_btn" @click="editFilling(1)">新建</a-button>
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      @change="onPage"
      :rowKey="(item) => item.autismClassificationId"
      :loading="loading"
      :columns="columns"
      :data-source="tableData"
      :pagination="{
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, i">
        {{ (pageNumber - 1) * pageSize + i + 1 }}
      </template>

      <template slot="order" slot-scope="item, row, i">
        <span class="blueText">
          <span v-show="(pageNumber - 1) * 10 + i + 1 !=1" @click="moveData(1,row.autismClassificationId)">上移</span>
          <span v-show="(pageNumber - 1) * 10 + i + 1 !=1 && (pageNumber - 1) * 10 + i + 1 != total"> | </span>
          <span v-show="(pageNumber - 1) * 10 + i + 1 != total" @click="moveData(2,row.autismClassificationId)">下移</span>
        </span>
      </template>

      <template slot="operation" slot-scope="item, row, index">
        <span class="blueText">
          <span @click="editFilling(2, row)">编辑</span> |
          <span @click="deleteData(row)">删除</span>
        </span>
      </template>
    </a-table>
    <a-modal
      v-model="edit"
      width="530px"
      title="新建分类"
      :centered="true"
      :closable="false"
    >
      <div class="all_content_box">
        <div class="L_R_inner">
          <span class="all_left_name">分类名称</span>
          <div class="right_Div">
            <a-input
              class="all_input"
              placeholder="请输入分类名称"
              v-model="typeName"
            />
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button :loading="loading" type="primary" @click="addEditData()">确认</a-button>
        <a-button @click="editCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "名称名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "终端展示顺序",
    align: "center",
    dataIndex: "createTime",
    scopedSlots: { customRender: "order" },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  // 可用组件的哈希表
  components: { HeaderBox }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      columns,
      loading: false,
      state: 1, //1添加 2编辑
      newTypeObj: {}, //分类名称obj
      typeName: "", //分类名称
      autismClassificationId: '',
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      edit: false,
    };
  }, // 事件处理器
  methods: {
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // 分类
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/autism/classification/list",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 新增/修改
    addEditData() {
      if (!this.typeName) {
        this.$message.warning("请填写分类内容");
        return;
      }
      let url = "";
      let params = {};
      let method = "";
      // 新增
      if (this.state == 1) {
        url = "/hxclass-management/autism/classification/add";
        method = "post";
        params = {
          name: this.typeName
        }
      }
      // 编辑
      else if (this.state == 2) {
        url = "/hxclass-management/autism/classification/update";
        method = "PUT";
        params = {
          autismClassificationId: this.autismClassificationId,
          name: this.typeName
        }
      }
      this.loading = true;
      this.$ajax({
        url,
        method,
        params,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.typeName = "";
          this.getData();
          this.edit = false;

          this.$message.success(this.state == 1 ? "新增成功" : "修改成功");
        } else {
          this.edit = false;

          this.$message.warning(res.message);
        }
      });
    },
    // 删除
    deleteData(item) {
      // console.log(item)
      let _this = this;
      this.$confirm({
        title: "确定删除该分类吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/autism/classification/del?autismClassificationId=" + item.autismClassificationId,
              method: "DELETE",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.pageNumber = 1
                _this.getData();
                _this.$message.success("删除成功");
              }
            });
        },
        onCancel() {},
      });
    },
    // 上移/下移
    moveData(move, id) {
      this.$ajax({
        url:"/hxclass-management/autism/classification/remove",
        method: "PUT",
        params: {
          move: move,
          autismClassificationId: id
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getData();
          this.$message.success(type == 1 ? "下移成功" : "上移成功");
        }
      });
    },
    // 打开弹出层
    editFilling(type, item) {
      this.typeName = null;
      // console.log(type, item);
      this.state = type;
      this.newTypeObj = item || {};
      // 修改
      if (type == 2) {
        this.autismClassificationId = item.autismClassificationId
        this.typeName = item.name;
      }
      this.edit = true;
    },
    editCancel(e) {
      this.edit = false;
      this.typeName = "";
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    // 资讯分类
    this.getData();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;
}
.all_content_box {
  .all_input {
    width: 340px;
  }
}
/deep/.ant-modal-header {
  padding: 16px 24px 0px;
  border-bottom: none;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 24px 24px 14px;
}
/deep/ .ant-btn {
  margin-right: 24px;
}
.blueText span {
  cursor: pointer;
}
</style>
